import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import MapContainer from './myMap.js';
import Example from './myGallery.js';
// import chefIconImage from './assets/images/exterior.jpg';
// import carouselImageSample1 from './assets/images/carousel/foodCarousel.jpg';
// import carouselImageSample2 from './assets/images/carousel/kinderCarousel.jpg';
// import carouselImageSample3 from './assets/images/carousel/interiorCarousel.jpg';

// import ReactImageParallax from './react-image-parallax.js';
// import CssToMatrix from 'css-to-matrix';
//import ParallaxImage from  'react-image-parallax2';
//import ImageParallax from './react-image-parallax';
//<div>Icons made by <a href="https://www.flaticon.com/authors/itim2101" title="itim2101">itim2101</a> from <a href="https://www.flaticon.com/" 			    title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" 			    title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div>

// var sectionStyle = {
//     width: "100%",
//     height: "550%",
//     backgroundImage: `url(${chefIconImage})`
//   };

  var liPath = "https://image.flaticon.com/icons/svg/25/25477.svg";
  
const App = () => (

<div> 
    
<section id="title">

        <div >

            <nav class="fixed-top navbar  navbar-expand-lg navbar-dark headSection ">

                    <a class="navbar-brand" href="">Restauracja Łużyczanka</a>

                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">

                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                               <a class="nav-link" href="#secondImageParallax">O&nbsp;nas</a>
                            </li>
                            <li class="nav-item">
                                 <a class="nav-link" href="#offer">Oferta</a>
                            </li>
                            <li class="nav-item">
                                 <a class="nav-link" href="#catering">Catering</a>
                            </li>
                            <li class="nav-item">
                                 <a class="nav-link" href="#gallery">Galeria</a>
                            </li>
                            <li class="nav-item">
                                 <a class="nav-link" href="#contact">Kontakt</a>
                            </li>
                        </ul>

                    </div>
             </nav>
        </div>
 </section>
 <section  id="heading">

    <div id="titleText">
        Zadowolony gość to w przyszłości Klient naszej restauracji.
    </div>
                       
</section>

<section id="secondImageParallax">
        <div class="secondImageParallax2left">   
        
         </div>
       <div class="secondImageParallax2right">    
         <h1>O&nbsp;nas</h1>  
                Restauracji Łużyczanka to doskonałe miejsce na celebrowanie wyjątkowych chwil i wydarzeń. 19-sto letnia przygoda z gastronomią wiąże się z wyjątkowym podejściem do klienta oraz elastycznością przy doborze menu w celu sprostania wszelakim gustom kulinarnym naszych gości. A precyzja i dbałość to domeny Restauracji Łużyczanka.
                    Do dyspozycji gości są dwie klimatyzowane sale na odpowiednio 60 i 160 osób. 
                    Dodatkowo dysponujemy ogródkiem zewnętrznym, miejscami parkingowymi oraz placem zabaw dla dzieci oraz boiskiem.
                    Z przyjemnością zorganizujemy dla Państwa wyjątkowe przyjęcia okolicznościowe, takie jak: wesela, chrzciny, komunie, 18-ste. urodziny, rocznice, imprezy rodzinne. Do współpracy zapraszamy również firmy dla których zorganizujemy imprezy firmowe, spotkania klasowe i stypy. Z wielką przyjemnością podejmiemy się organizacji każdej Państwa uroczystości zgodnie z życzeniem i sugestiami.
       </div>
</section>

<section id="myIconsSection">  
            <div class="row">

                <div class="aboutUs-box col-lg-4">        
                    <i class="icon fas fa-utensils fa-4x"></i>
                    <h3 class="aboutUs-title">Smaczna kuchnia.</h3>
                    <p>Nasi kucharze zadbają o Państwa podniebienie.</p>
                </div>

                <div class="aboutUs-box col-lg-4">
                    <i class="icon fas fa-bullseye fa-4x"></i>
                    <h3 class="aboutUs-title">Idealny wybór</h3>
                    <p>Wybór naszych usług to strzał w 10!</p>
                </div>

                <div class="aboutUs-box col-lg-4">       
                    <i class="icon far fa-star fa-4x"></i>                   
                    <h3 class="aboutUs-title">Gwarancja najwyższej jakości.</h3>
                    <p>Dbamy o każdy szczegół by każda impreza była wspaniałym przeżyciem.</p>
                </div>
            </div> 


       
        
</section>

<section  id="offer">

        <h1>Oferta</h1>
        Każda nasza oferta jest indywidualna i kompleksowa, doskonale dopasowana do życzeń naszych gości.
        W cenie przyjęcia oferujemy: <br/><br/><br/>
        <ul className="ul-columns">
            <div className="offer-leftColumn">
                <li><img width="10" height="10" src={liPath} /> Klimatyzowaną salę weselą</li>
                <li><img width="10" height="10" src={liPath} /> Dekorację wejścia: czerwony dywan</li>
                <li><img width="10" height="10" src={liPath} /> Dekorację stołów: wazony, świeczniki,<br/> świece, bieżniki, bankietówki , falbany</li>
                <li><img width="10" height="10" src={liPath} /> Pokrowce na krzesła</li>
                <li><img width="10" height="10" src={liPath} /> Profesjonalną obsługę kelnerską</li>
                <li><img width="10" height="10" src={liPath} /> Alkohole i napoje w atrakcyjnych cenach</li>
                <li><img width="10" height="10" src={liPath} /> Parking</li>
                <li><img width="10" height="10" src={liPath} /> Możliwość skorzystania z sejfu</li>
                <li><img width="10" height="10" src={liPath} /> Fotelik dla malucha (do karmienia)</li>
                <li><img width="10" height="10" src={liPath} /> Kącik dla dzieci z zabawkami</li>
                <li><img width="10" height="10" src={liPath} /> Opakowania na produkty (na dania które<br/> zostaną po przyjęciu</li>
                <li><img width="10" height="10" src={liPath} /> Efektowne tło za Parą Młodą</li>
                <li><img width="10" height="10" src={liPath} /> Tradycyjne powitanie Pary Młodej chlebem i solą</li>
                <li><img width="10" height="10" src={liPath} /> Powitanie gości kieliszkiem szampana</li><br/>    
            </div>
            <div className="offer-rightColumn">
  
                <h4>Opcje dodatkowe:</h4>
                <li><img width="10" height="10" src={liPath} /> Candy bar</li>
                <li><img width="10" height="10" src={liPath} /> Wiejski stół</li>
                <li><img width="10" height="10" src={liPath} /> Barman serwujący drinki</li>
                <li><img width="10" height="10" src={liPath} /> Tort weselny i ciasto z zaprzyjaźnionej cukierni</li>
                <li><img width="10" height="10" src={liPath} /> Oprawa muzyczna</li>
                <li><img width="10" height="10" src={liPath} /> Możliwość współpracy z florystką</li>
                <li><img width="10" height="10" src={liPath} /> Taniec „w chmurach”</li>
                <li><img width="10" height="10" src={liPath} /> Napis LOVE – wynajem</li>
                <li><img width="10" height="10" src={liPath} /> Fontanna czekoladowa</li>
                <li><img width="10" height="10" src={liPath} /> Czekoladziarka</li>
                <li><img width="10" height="10" src={liPath} /> Balony z helem</li>
                <li><img width="10" height="10" src={liPath} /> Animator dla dzieci</li>
                <li><img width="10" height="10" src={liPath} /> Dekoracja z żywych kwiatów</li>
            </div>
        </ul>
    
        <div className="offer-footer">
        Organizowanie przyjęć to nasza prawdziwa pasja, której z przyjemnością się poświęcamy. Do każdej uroczystości weselnej podchodzimy indywidualnie i przede wszystkim chcemy spełniać  Państwa życzenia, aby ten dzień był dokładnie taki, jaki Państwo sobie wymarzyli. Najważniejszym celem, jaki stawiamy sobie podczas organizowania przyjęć jest satysfakcja Pary Młodej oraz Gości. Zadowolenie naszych Klientów stanowi dla nas najlepszą nagrodę.
        </div>        
</section>
<section id="myCarousel">
        
        <div id="offer-carousel" class="carousel slide" data-ride="false">
            <div class="carousel-inner">
                <div class="carousel-item active container-fluid">
                    <h2 class="offer-text"><font size="10">&#8222;</font>Profesjonalnie wykonana praca, polecam.<font size="7">&#8221;</font></h2>
                    <em>Monika, Kalisz</em>
                </div>
                <div class="carousel-item container-fluid">
                    <h2 class="offer-text"><font size="10">&#8222;</font>Świetny kontakt z obsługą, odpowiadała na wszelkie potrzeby gości, nawet te najdziwniejsze :)<font size="7">&#8221;</font></h2>
                    <em>Marzena, Opatówek</em>
                </div>
                <div class="carousel-item container-fluid">
                    <h2 class="offer-text"><font size="10">&#8222;</font>Najsmaczniejsza kuchnia w okolicy, świetne miejsce na zorganizowanie wesela, polecam<font size="7">&#8221;</font></h2>
                    <em>Krystian, Wrocław</em>
                </div>
            </div>
            <a class="s carousel-control-prev s" href="#offer-carousel" role="button" data-slide="prev">
             <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="s carousel-control-next s" href="#offer-carousel" role="button" data-slide="next">
             <span class="carousel-control-next-icon"></span>
            </a>
        </div> 

</section>

<section  id="catering" >

        <h1>Catering</h1>
        Organizujemy dowolne przyjęcia w wybranym przez Państwa miejscu zapewniając kompleksową obsługę. W szerokiej gamie naszych usług zajmujemy się także dostarczaniem posiłków regeneracyjnych i dietetycznych. Dokładamy wszelkich starań w celu sprostania wszelkim oczekiwaniom naszych Gości.
        Zajmujemy się całościową organizacją imprez zewnętrznych odbywających się w plenerze, w siedzibie klienta czy też u Niego w domu.
        Obsługujemy wyjazdowe imprezy okolicznościowe tj: <br/><br/>
        {/* <div class="ulParent">
            <div class="unList">
      
            </div>
            
            <div class="unList2"> */}
                <ul className="ul-columns">
                <li><img width="10" height="10" src={liPath} color="FF7F51"/> Bankiety, gale</li>
                <li><img width="10" height="10" src={liPath} /> Spotkania firmowe, konferencje i szkolenia</li>
                <li><img width="10" height="10" src={liPath} /> Wesela, obiady po ślubie</li>
                <li><img width="10" height="10" src={liPath} /> Imprezy plenerowe</li>
                <li><img width="10" height="10" src={liPath} /> Imprezy koktajlowe</li>
                <li><img width="10" height="10" src={liPath} /> Imprezy okolicznościowe w Twoim domu – komunia, urodziny, itp.</li>
                </ul>
            {/* </div>
            <div class="unList3">
           
            </div>
        </div>
        <div class="stopList"></div> */}
        <br/>
        <p>
        Nasi pracownicy z przyjemnością zajmą się organizacją imprezy, abyście Państwo mogli spokojnie zająć się tym, co w życiu najważniejsze. Zaproponujemy menu dopasowane do potrzeb i preferencji klienta oraz najwyższej klasy obsługę.
        Przygotowujemy również specjalne oferty ze świątecznymi daniami (Wielkanoc, Boże Narodzenie) dla firm oraz  klientów indywidulnych ceniących sobie tradycję i dobry smak.
        Słyniemy z doskonałych deserów, które cieszą się dużym zainteresowaniem.
        Wszędzie tam, gdzie zamarzycie Państwo zorganizować imprezę, my jesteśmy w stanie przygotować catering.
        </p>
        <p>
        Przygotowujemy również posiłki regeneracyjne, które są ważnym elementem codziennej diety pracownika. Systematycznie dostarczane, zbilansowane posiłki wpływają na zdrowie, samopoczucie i poprawiają motywację do pracy, gdyż są odbierane jako forma gratyfikacji za wykonywaną pracę i mogą umocnić przywiązanie pracownika do firmy.
        Posiłki regeneracyjne oferowane przez Restaurację Łużyczankę są:
        </p>
        <ul className="ul-columns">
        
        
        <li>zgodne z ustawowymi wytycznymi;</li>
        <li>indywidualne menu, układane według wymagań i z właściwym wyprzedzeniem</li>
        <li>odpowiednio spakowane i zabezpieczone dania, dostarczane w  jednorazowych pojemnikach termicznych pod podany adres na określoną godzinę</li>
        </ul>
        <br/>
        Posiłki komponujemy jedynie ze świeżych produktów, a dania przygotowywane są na bieżąco.<br/><br/>
            Przykładowe menu:
        Poniedziałek:  Zupa pomidorowa z makaronem, Kotlet schabowy z cebulką, ziemniaki, surówka z białej kapusty
        Wtorek:  Zupa ogórkowa, Gulasz wieprzowy, ziemniaki/kasza, buraczki
        Środa: Flaki wołowo-wieprzowe z pieczywem, Pieczeń z karkówki, ziemniaki, surówka z marchwi
        Czwartek: Barszcz zabielany z makaronem, Schab w sosie śmietanowym, ziemniaki, surówka z kapusty pekińskiej
        Piątek: Zupa szczawiowa z jajkiem, Filet rybny , frytki, surówka z kapusty kiszonej.
        
        <b> Zaznaczamy, iż wymienione przez nas warianty są tylko propozycją. </b>
        Szczegóły zamówienia i ostateczną cenę ustalamy zawsze po wykonaniu indywidualnej konsultacji.
        
       
 </section>

<section  id="gallery">

        <h1>Galeria</h1>
        <h6>Kliknij by zobaczyć więcej </h6>
        {/* <div id="gallery-carousel" class="carousel slide" data-ride="false">
            <div class="carousel-inner">
                <div class="carousel-item active container-fluid">
                      <img src={carouselImageSample1} alt="" class="img img-fluid"/>
                </div>
                <div class="carousel-item container-fluid">
                    <img src={carouselImageSample2} alt="" class="img img-fluid" />
                </div>
                <div class="carousel-item container-fluid">
                     <img src={carouselImageSample3} alt="" class="img img-fluid" />
                </div>
            </div>
            <a class="s carousel-control-prev s" href="#gallery-carousel" role="button" data-slide="prev">
             <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="s carousel-control-next s" href="#gallery-carousel" role="button" data-slide="next">
             <span class="carousel-control-next-icon"></span>
            </a>
        </div>  */}
       

        <div>
            <Example />
        </div> 
        

</section>

<section  id="contact">

        
        <br /><br /><br />
        <h1>Kontakt</h1>
        <h6>605338858 / 627312034</h6>
        <h6>marko.idzikowski@wp.pl</h6>
        <br />
        <h6>Restauracja Łużyczanka</h6>
        <h6>Ul. Wieluńska 41, Kraszewice 63-522</h6>

        <br /><br /><br />
      
        {/* <div class="mapWrapper">
            <div  id="map" >
                <MapContainer />      
            </div> 
        </div> */}

       
</section>

<section  id="shield">

     
        <h6>Przedsiębiorca uzyskał subwencje finansową, w ramach programu rządowego.</h6>
        <h6>Tarcza finansowa 2.0 Polskiego Funduszu Rozwoju dla mikro, małych i średnich firm udzielono przez PFR S.A</h6>

       
</section>


<section id="footer">
    <div class="container-fluid">
        <p>© Copyright 2022 Restauracja Łużyczanka</p>
    </div>
</section>

</div>  );


export default App;
