import React, { Component } from 'react'
import ReactBnbGallery from 'react-bnb-gallery'
import './galleryStyle.css';

import carouselImageSample1 from './assets/images/carousel/foodCarousel.jpg';
import carouselImageSample2 from './assets/images/carousel/kinderCarousel.jpg';
import carouselImageSample3 from './assets/images/carousel/interiorCarousel.jpg';


// import cakeImage from './assets/images/cake.jpg';
// import decorationImage from './assets/images/decoration.jpg';
// import exterior1Image from './assets/images/exterior.jpg';
// import exterior2Image from './assets/images/exterior2.jpg';
// import floorImage from './assets/images/floor.jpg';
// import foodTableImage from './assets/images/foodTable.jpg';
// import foodTable2Image from './assets/images/foodTable2.jpg';
// import interiorEmptyImage from './assets/images/interiorEmpty.jpg';
// import interiorMainTableImage from './assets/images/interiorMainTable.jpg';
// import interiorMainTable2Image from './assets/images/interiorMainTable2.jpg';
// import mainTable2Image from './assets/images/mainTable2.jpg';
// import sushiImage from './assets/images/sushi.jpg';
import kinder1Image from './assets/images/kinder1.jpeg';
import kinder2Image from './assets/images/kinder2.jpeg';
import newGardenImage from  './assets/images/newGarden.jpeg';
import newSushiImage from  './assets/images/newSushi.jpeg';
// import barmanImage from  './assets/images/barmanBig.jpg';

import newgardenImageSmall from './assets/images/thumbnail/gardenSmall.jpg';
// import newSushiImageSmall from './assets/images/thumbnail/sushiSmall.jpg';
// import cakeImageSmall from './assets/images/cake.jpg';
// import decorationImageSmall from './assets/images/decoration.jpg';
// import exterior1ImageSmall from './assets/images/exterior.jpg';
// import exterior2ImageSmall from './assets/images/exterior2.jpg';
// import floorImageSmall from './assets/images/floor.jpg';
// import foodTableImageSmall from './assets/images/foodTable.jpg';
// import foodTable2ImageSmall from './assets/images/foodTable2.jpg';
// import interiorEmptyImageSmall from './assets/images/interiorEmpty.jpg';
// import interiorMainTableImageSmall from './assets/images/interiorMainTable.jpg';
// import interiorMainTable2ImageSmall from './assets/images/interiorMainTable2.jpg';
// import mainTable2ImageSmall from './assets/images/mainTable2.jpg';
// import sushiImageSmall from './assets/images/sushi.jpg';
import kinder1ImageSmall from './assets/images/thumbnail/13.jpg'
import kinder2ImageSmall from './assets/images/thumbnail/14.jpg'
// import barmanImageSmall from './assets/images/thumbnail/barman.jpg'

const photos = [
 
  //  {
  //   photo: interiorEmptyImage,
  //   caption: "",
  //   subcaption: "",
  //   thumbnail: interiorEmptyImageSmall,
  // }
  // , 
  // {
  //   photo: interiorMainTable2Image,
  //   caption: "",
  //   subcaption: "",
  //   thumbnail: interiorMainTable2ImageSmall,
  // }
  // , {
  //   photo: mainTable2Image,
  //   caption: "",
  //   subcaption: "",
  //   thumbnail: mainTable2ImageSmall,
  // },
   {
    photo: kinder1Image,
    caption: "",
    subcaption: "",
    thumbnail: kinder1ImageSmall,
  }
  , {
    photo: kinder2Image,
    caption: "",
    subcaption: "",
    thumbnail: kinder2ImageSmall,
  },
  {
    photo: newGardenImage,
    caption: "",
    subcaption: "",
    thumbnail: newgardenImageSmall
  },
  {
  photo: newSushiImage,
  caption: "",
  subcaption: "",
  thumbnail: newSushiImage,
}
// , {
//   photo: foodTableImage,
//   caption: "",
//   subcaption: "",
//   thumbnail: foodTableImageSmall,
// }
// , {
//   photo: decorationImage,
//   caption: "",
//   subcaption: "",
//   thumbnail: decorationImageSmall,
// }
// , {
//   photo: floorImage,
//   caption: "",
//   subcaption: "",
//   thumbnail: floorImageSmall,
// }
// , 
// {
//   photo: cakeImage,
//   caption: "",
//   subcaption: "",
//   thumbnail: cakeImageSmall,
// },
// {
//   photo: barmanImage,
//   caption: "",
//   subcaption: "",
//   thumbnail: barmanImageSmall,
// }

];

class Example extends Component {
  constructor() {
    super(...arguments);
    this.state = { galleryOpened: false };
    this.toggleGallery = this.toggleGallery.bind(this);
  }

  toggleGallery() {
    this.setState(prevState => ({
      galleryOpened: !prevState.galleryOpened
    }));
  }

  render () {
    return (
        <>
           <div id="gallery-carousel" class="carousel slide" data-ride="false">
            <button  className="galleryButton" onClick={this.toggleGallery}>
            <div class="carousel-inner">
                <div class="carousel-item active container-fluid">
                      <img src={carouselImageSample1} alt="" class="img img-fluid"/>
                </div>
                <div class="carousel-item container-fluid">
                    <img src={carouselImageSample2} alt="" class="img img-fluid" />
                </div>
                <div class="carousel-item container-fluid">
                     <img src={carouselImageSample3} alt="" class="img img-fluid" />
                </div>
            </div>
              </button>
            <a class="s carousel-control-prev s" href="#gallery-carousel" role="button" data-slide="prev">
             <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="s carousel-control-next s" href="#gallery-carousel" role="button" data-slide="next">
             <span class="carousel-control-next-icon"></span>
            </a>
        </div> 

            <br/>
              {/* <i class="icon far fa-hand-pointer fa-4x"></i> */}
           
            <ReactBnbGallery
                show={this.state.galleryOpened}
                photos={photos}
                onClose={this.toggleGallery} />
             
        </>
    )
  }
}

export default Example;