import React from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 
//note: code formatted for ES6 here
export class MapContainer extends React.Component {



  render() {
    return (
      <Map
          google={this.props.google}
        
          initialCenter={{
            lat: 51.51823,
            lng: 18.22145
          }}
          zoom={15}
          onClick={this.onMapClicked}
        >
          <Marker
          title={'Tutaj jesteśmy!'}
          name={'Łuzyczanka'}
           position={{lat: 51.51570, lng: 18.22555}} />
       </Map>
    
    );
  }
}
// export class MapContainer extends React.Component {
  
//   static defaultProps = {
//     initialCenter: { lat: 90.7608, lng: -111.8910 },
//     zoom: 14,
// };

// constructor(props) {
//   super(props);
// }

// render() {
//   return (
       
//           // center = {this.props.center}
//           // zoom = {this.props.zoom}

//         // <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
//         //   <Marker position={{ lat: -34.397, lng: 150.644 }} />
//         // </GoogleMap>

//       // <Map 

//       //     //google={this.props.google}
//       //     initialCenter= {this.defaultProps.center}
//       //     center= {this.defaultProps.center}
//       //     zoom={this.defaultProps.zoom}
//       //     onClick={this.onMapClicked}
//       //     defaultCenter={{ lat: -94.397, lng: 150.644 }}
//       //     >

//       //     {/* <Marker position={{ lat: -94.397, lng: 150.644 }}/>
//       //       <Marker onClick={this.onMarkerClick}
//       //               name={'Current location'} />
    
//       //       <InfoWindow onClose={this.onInfoWindowClose}>
//       //           <div>
//       //             <h1>{this.state.selectedPlace.name}</h1>
//       //           </div>
//       //       </InfoWindow> */}
//       // </Map>
//       <Map google={this.props.google} zoom={14}>
 
//           <Marker onClick={this.onMarkerClick}
//                   name={'Current location'} />

//           <InfoWindow onClose={this.onInfoWindowClose}>
//               <div>
//                 <h1>{this.state.selectedPlace.name}</h1>
//               </div>
//           </InfoWindow>
      
            
//             google={this.props.google}
//             initialCenter={{
//                 lat: 42.39,
//                 lng: -72.52
//             }}

//     </Map>
//     );
//   }
// }

export default GoogleApiWrapper({
    apiKey: ("AIzaSyBB5v-3LEJG02PxLP5AtszAqSoCcjoS6EA")
  })(MapContainer)   
